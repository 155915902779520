import { makeStyles } from '@material-ui/core/styles';

const IMAGE_WIDTH = 250;
const IMAGE_HEIGHT = 184;

export const useStyles = makeStyles((theme) => ({
  imageOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    minHeight: IMAGE_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    zIndex: 3,
    color: 'white',
    backgroundColor: theme.palette.grey.A400, // '#1537B9',
    opacity: 0,
    transition: 'opacity .5s',
    '&:hover': {
      opacity: 0.92,
      transition: 'opacity .5s',
    },
    '&.fav-tool': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.clear-tool': {
      backgroundColor: 'transparent',
    },
  },
  textWrapper: {
    padding: 6,
    '& > *': {
      fontSize: '1.2rem',
      textAlign: 'center',
    },
  },
  cardSelectIcon: {
    position: 'absolute',
    top: 'calc(184px + .625em)',
    left: 2,
    color: 'transparent',
    width: 25,
    height: 18,
    transition: 'color .5s',
  },
  itemFooter: {
    margin: '-1px 10px 10px',
    padding: '0 21px 0 15px',
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: 13,
    fontWeight: 700,
    color: '#5F5F5F',
    // '$itemSelected &': {
    //   color: theme.palette.primary.main,
    // },
  },
  favoriteItemFooter: {
    color: theme.palette.primary.dark,
  },
  tableItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
    minHeight: 46,
    color: '#999',
  },
  goButton: {
    zIndex: 10,
    color: '#FFFC',
    '&:hover': {
      color: '#FFFE',
      transition: 'color .35s',
    },
    '&.clear-goButton, &.clear-goButton:hover': {
      color: theme.palette.grey.A700,
      transition: 'color .35s',
      '& svg[data-favorite="true"]': {
        color: theme.palette.primary.dark,
        transition: 'color .35s',
      },
    },
    '&.MuiIconButton-root': {
      position: 'absolute',
      top: -12,
      right: -12,
    },
    margin: '10px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  collapse: {
    width: '100%',
  },
}));

export default useStyles;
