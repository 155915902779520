import { makeStyles } from '@material-ui/core/styles';

const ITEM_WIDTH = 265;
const ITEM_HEIGHT = 200;
const IMAGE_WIDTH = 250;
const IMAGE_HEIGHT = 184;

const hoverTableStyles = (theme, selected) => ({
  background: '#F5F8FC',
  boxShadow: selected ? '0 5px 10px 0 rgba(0,0,0,.4)' : '0 3px 8px 0 rgba(0,0,0,.3)',
  '& $cardSelectIcon': {
    color: theme.palette.grey.A700,
  },
  transition: 'box-shadow .5s, color .2s',
});

export const useStyles = makeStyles((theme) => ({
  grid: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    '& > div': {
      paddingTop: 20,
      paddingBottom: 40,
    },
  },
  tableHeaderAndScroll: {
    width: '100%',
    overflow: 'hidden',
  },
  gridItems: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '100%',
    minWidth: '100%',
    height: 'auto',
  },
  gridItemsScroll: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    paddingRight: 5,
    paddingLeft: 5,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  imageItem: {
    display: 'block',
    position: 'relative',
    margin: 7,
    backgroundColor: '#F3F3F3',
    minWidth: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    minHeight: IMAGE_HEIGHT,
    border: '1px solid #E7E7E7',
    borderRadius: 4,
    overflow: 'hidden',
  },
  item: {
    position: 'relative',
    minHeight: ITEM_HEIGHT,
    height: 'auto',
    width: ITEM_WIDTH,
    minWidth: 'auto',
    borderBottom: '#ebebeb 1px solid',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
    margin: '8px',
    borderRadius: 10,
    boxShadow: '0 2px 7px 0 rgba(0,0,0,.1)',
    transition: 'background .2s, box-shadow .5s',
    '&:hover': hoverTableStyles(theme, false),
    '& div[data-element="layer"]': {
      backgroundImage: 'linear-gradient(#FFF0, #FFF0 40%, #04D5 75%, #04D9)',
    },
    '& div[data-element="hover"]': {
      zIndex: 2,
      backgroundColor: '#25c',
      opacity: 0,
      transition: 'opacity .5s',
      '&:hover': {
        opacity: 1,
        transition: 'opacity .5s',
      },
    },
  },
  itemSelected: {
    position: 'relative',
    minHeight: ITEM_HEIGHT,
    width: ITEM_WIDTH,
    minWidth: 'auto',
    backgroundColor: '#F5F8FC',
    borderBottom: '#ebebeb 1px solid',
    color: 'rgba(0, 0, 0, 0.87)',
    height: 'auto',
    margin: '8px',
    borderRadius: 10,
    boxShadow: '0 5px 10px 0 rgba(0,0,0,.5)',
    transition: 'background .2s, box-shadow .5s',
    '&:hover': hoverTableStyles(theme, true),
    '& $cardSelectIcon': {
      color: theme.palette.grey.A700,
      transition: 'color .5s',
    },
    '& .item-no-message': {
      whiteSpace: 'break-spaces',
      overflow: 'visible',
    },
    '& div[data-element="layer"]': {
      backgroundImage: 'linear-gradient(#FFF0, #FFF0 40%, #04D5 75%, #04D9)',
    },
  },
  cardSelectIcon: {
    position: 'absolute',
    top: 'calc(184px + .625em)',
    left: 2,
    color: 'transparent',
    width: 25,
    height: 18,
    transition: 'color .5s',
  },
}));
