import React from 'react';

import Layout from '../../components/MenuLayout';
import { AdvancedToolsView } from '../../components/AdvancedTools/AdvancedToolsView';

export default () => (
  <Layout type="provider">
    <AdvancedToolsView />
  </Layout>
);
