import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  layer: {
    position: 'absolute',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    zIndex: 2,
    left: 0,
    right: 0,
    bottom: 4,
    top: 0,
    padding: '1rem',
    color: 'white',
    fontWeight: 600,
    minHeight: 168,
    '& .name': {
      position: 'absolute',
      top: '1rem',
      left: '1rem',
      textAlign: 'left',
      textShadow: '1px 1px 5px #000E',
    },
    '& .tags': {
      display: 'flex',
      flexWrap: 'wrap-reverse',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignContent: 'flex-start',
      position: 'absolute',
      bottom: 0,
      width: 244,
      height: 120,
      paddingBottom: 8,
    },
    '& .category': {
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.grey.A400,
      backgroundColor: '#fffe',
      margin: 2,
      padding: '3px 10px',
      border: 'solid 1px #ddd',
      borderRadius: '4px',
    },
    '& span': {
      alignSelf: 'end',
      paddingBottom: 16,
      justifySelf: 'center',
      fontSize: '1.1875em',
      textShadow: '1px 1px 5px #000E',
      opacity: 1,
      transition: 'opacity .75s',
      '&:hover': {
        opacity: 0,
        transition: 'opacity .75s',
      },
    },
  },
}));

export const CardImageLayer = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.layer} data-element="layer">
      {children}
    </Box>
  );
};
