import React from 'react';
import Box from '@material-ui/core/Box';

import { useStyles } from '../styled/StudyAdvancedToolCardMakeStyles';

export const AdvancedToolCardImageFooter = ({ node }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.itemFooter} ${node.favorite && classes.favoriteItemFooter}`}>
      {node.name}
    </Box>
  );
};

export default AdvancedToolCardImageFooter;
