import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    width: '100&',
    height: '100%',
    '& img': {
      position: 'absolute',
      width: '100%',
      maxHeight: '100%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      objectFit: 'cover',
    },
  },
}));

export const CardImageWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {children}
    </Box>
  );
};
