import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNewOutlined';
import { useTranslation } from 'react-i18next';
import { InnerHTML } from '../../Common/InnerHTML';
import { useStyles } from '../styled/StudyAdvancedToolCardMakeStyles';

export const AdvancedToolCardImageOverlay = ({ node, index, cardActionButton, handleGoto }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const ActionButton = cardActionButton || {
    Icon: OpenInNewIcon,
    tooltip: 'open',
    inProgress: null,
    disabled: false,
  };

  return (
    <Box className={`${classes.imageOverlay} ${!!node.favorite && 'fav-tool'} ${!node.info5 && 'clear-tool'}`}>
      <Box className={classes.textWrapper}>
        <InnerHTML content={node.info5} />
      </Box>
      {!node.noAction && (
      <Tooltip title={t(ActionButton.tooltip)} TransitionComponent={Zoom} placement="bottom" arrow>
        <IconButton
          disabled={ActionButton.inProgress === index}
          className={`${classes.goButton} ${!node.info5 && 'clear-goButton'}`}
          onClick={(evt) => handleGoto(evt, index)}
        >
          <ActionButton.Icon data-favorite={node.favorite} />
        </IconButton>
      </Tooltip>
      )}
    </Box>
  );
};

export default AdvancedToolCardImageOverlay;
