import gql from 'graphql-tag';

export const GET_ADVANCED_TOOLS = gql`
  query ($first: Int, $providerUuid: ID, $hospitalUuid: ID, $category: String, $onlyFavorites: Boolean, $mostUsed: Boolean, $cursor: String) {
    advancedTools(first: $first, after: $cursor, providerUuid: $providerUuid, hospitalUuid: $hospitalUuid, category: $category, onlyFavorites: $onlyFavorites, mostUsed: $mostUsed) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ... on AdvancedTool {
            __typename
            uuid
            provider {
              uuid
              name
              ownerUuid
              owner {
                uuid
                name
                surname
              }
              logo
              web
              createdAt
              updatedAt
            }
            name
            description
            category
            visible
            image
            info1
            info2
            info3
            info4
            info5
            speciality
            bodyRegion
            fdaCertification
            ceCertification
            research
            creditCost
            endpoint
            useIframe
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const GET_HOSPITAL_ADVANCED_TOOLS = gql`
  query ($uuid: ID!) {
    hospital(uuid: $uuid) {
      uuid
      availableAdvancedTools {
        uuid
        provider {
          uuid
          name
        }
        name
        description
      }
    }
  }
`;

export const GET_ADVANCED_TOOL = gql`
  query ($uuid: ID!) {
    advancedTool(uuid: $uuid) {
      uuid
      provider {
        uuid
        name
        ownerUuid
        owner {
          uuid
          name
          surname
        }
        logo
        web
        createdAt
        updatedAt
      }
      name
      description
      category
      visible
      image
      reportModel
      reportView
      info1
      info2
      info3
      info4
      info5
      speciality
      bodyRegion
      fdaCertification
      ceCertification
      research
      creditCost
      endpoint
      feedbackURL
      useIframe
      aeTitle
      createdAt
      updatedAt
    }
  }
`;

export const GET_ADVANCED_TOOL_IMAGE_MODALITIES = gql`
  query {
    advancedToolImageModalities {
      __typename
      category
    }
  }
`;

export const DELETE_ADVANCED_TOOL = gql`
  mutation ($advancedToolUuid: ID!) {
    deleteAdvancedTool(advancedToolUuid: $advancedToolUuid)
  }
`;

export const CREATE_ADVANCED_TOOL = gql`
  mutation ($input: CreateAdvancedToolInput!) {
    createAdvancedTool(input: $input) {
      uuid
      provider {
        uuid
        ownerUuid
        owner {
          uuid
          name
          surname
        }
        logo
        createdAt
        updatedAt
      }
      name
      description
      category
      visible
      image
      reportModel
      reportView
      info1
      info2
      info3
      info4
      info5
      speciality
      bodyRegion
      fdaCertification
      ceCertification
      research
      creditCost
      endpoint
      feedbackURL
      useIframe
      aeTitle
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ADVANCED_TOOL = gql`
  mutation ($input: UpdateAdvancedToolInput!) {
    updateAdvancedTool(input: $input) {
      uuid
      provider {
        uuid
        ownerUuid
        owner {
          uuid
          name
          surname
        }
        logo
        createdAt
        updatedAt
      }
      name
      description
      category
      visible
      image
      reportModel
      reportView
      info1
      info2
      info3
      info4
      info5
      speciality
      bodyRegion
      fdaCertification
      ceCertification
      research
      creditCost
      endpoint
      feedbackURL
      useIframe
      aeTitle
      createdAt
      updatedAt
    }
  }
`;

export const USE_ADVANCED_TOOL = gql`
  mutation ($advancedToolUuid: ID!, $studyUuid: ID!) {
    useAdvancedTool(advancedToolUuid: $advancedToolUuid, studyUuid: $studyUuid) {
      uuid
      reports {
        uuid
        status
        advancedTool {
          uuid
          name
          useIframe
          endpoint
          provider {
            uuid
            name
          }
        }
        study {
          uuid
          studyInstanceUid
        }
      }
    }
  }
`;
