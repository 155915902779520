import { makeStyles } from '@material-ui/core';

const TOOL_THEME_COLOR = '#457abf';

const useStyles = makeStyles((theme) => ({
  subheader: {
    borderBottom: '1px solid #0002',
    marginBottom: '.75em',
  },
  wrapper: {
    display: 'flex',
    minHeight: 'calc(100% - 3.5em)',
    flexDirection: 'row',
    marginTop: '1em',
    padding: '.75em 2em',
    marginBottom: '2em',
    overflow: 'hidden',
    '@media (max-width:600px)': {
      padding: '.75em .5em',
    },
  },
  box: {
    width: '100%',
    flexGrow: 1,
    padding: '0 1.25em',
    '@media (max-width:450px)': {
      padding: '0 .75em',
    },
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  title: {
    fontSize: '1.75em',
    fontWeight: 600,
    color: theme.palette.grey.A700,
  },
  subtitle: {
    borderBottom: '1px solid #0002',
    marginBottom: '1.125em',
    fontWeight: 700,
    color: theme.palette.grey.A200,
  },
  toolName: {
    fontSize: '1.625em',
    lineHeight: 1.2,
    fontWeight: 600,
    marginTop: '2.5em',
    color: theme.palette.grey.A400,
  },
  provider: {
    margin: '1em 0',
    fontWeight: 700,
    color: theme.palette.grey.A700,
  },
  bar: {
    height: 12,
    width: '250%',
    backgroundColor: TOOL_THEME_COLOR,
    zIndex: '2',
  },
  indent: {
    padding: '0 1em',
  },
  description: {
    width: 'calc(100% - 1em)',
    fontSize: '.875em',
    marginBottom: '.75em',
    textAlign: 'justify',
    '@media (max-width:600px)': {
      width: '100%',
    },
    '& div > div': {
      marginTop: '1.5em',
    },
    '& .MuiFormControlLabel-root > span': {
      lineHeight: '1.2 !important',
      textAlign: 'left',
      fontSize: '1.3rem !important',
    },
    '& .ai-tool-section > h1': {
      marginTop: '.5em',
      marginBottom: 0,
      padding: '.25em',
      fontSize: '1.25em',
      lineHeight: 1,
      fontWeight: 700,
      letterSpacing: 1,
      color: theme.palette.grey.A700,
    },
  },
  collapsed: {
    marginLeft: 8,
    padding: '0 1em',
    fontSize: '.875em',
    color: theme.palette.grey.A700,
  },
  image: {
    margin: '-.75em 0 .25em',
    width: 'calc(100% + 3.5em)',
    backgroundColor: '#444',
    height: '35em',
    overflow: 'hidden',
    '& img': {
      minHeight: '100%',
      width: '100%',
      minWidth: '100%',
      objectFit: 'cover',
    },
  },
  imageFooter: {
    marginBottom: '2em',
    color: TOOL_THEME_COLOR,
    letterSpacing: 1,
    '& a': {
      fontSize: '1.2rem',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  specifications: {
    width: '94%',
    margin: '2em 0',
    '& div': {
      letterSpacing: 0.75,
      textAlign: 'right',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& > div': {
        paddingLeft: 6,
      },
    },
    '& > div': {
      marginBottom: 8,
    },
  },
  specsName: {
    paddingRight: '.375em !important',
    fontSize: '.9375em',
    fontWeight: 700,
    color: TOOL_THEME_COLOR,
  },
  specsValue: {
    fontSize: '.875em',
    fontWeight: 600,
    color: 'white',
    backgroundColor: TOOL_THEME_COLOR,
  },
  diseases: {
    marginTop: '1.5em',
    width: 'calc(100% + 4em)',
    color: '#f9fcff',
    backgroundColor: TOOL_THEME_COLOR,
    lineHeight: 1.5,
    fontSize: '.9375em',
    fontWeight: 600,
    letterSpacing: 0.25,
    padding: '.25em 1em .5em 0',
    '& ul': {
      marginLeft: -10,
    },
  },
  creditInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1.5em',
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
    borderRadius: 4,
    '& span': {
      padding: '.25em .75em',
    },
  },
  credits: {
    padding: '.25em',
    fontSize: '1.375em',
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
  group: {
    margin: '1.5em .25em -.25em',
  },
  tool: {
    fontWeight: 600,
  },
  favorite: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  toolSubData: {
    marginTop: -4,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& .provider-name': {
      display: 'inline-flex',
      margin: '0 .5em 0 .125em',
      paddingTop: '.4em',
    },
  },
  chips: {
    marginRight: '.35em',
    marginTop: '.3em',
    paddingTop: '.2em',
    height: 20,
    backgroundColor: '#f3f3f3',
    '.tool-selected &': {
      backgroundColor: '#fdfdfd',
    },
    border: 'solid 1px #eaeaea',
    '& .MuiChip-label': {
      height: 14,
      fontSize: '.875em',
      fontWeight: 400,
      color: theme.palette.grey.A700,
    },
  },
  cardFooter: {
    height: 32,
    backgroundImage: `linear-gradient(90deg, #FFF0, ${TOOL_THEME_COLOR})`,
    margin: '2.5em -3.5em',
    width: 'calc(100% + 10em)',
  },
  reportView: {
    maxHeight: 175,
    maxWidth: 200,
    objectFit: 'contain',
    cursor: 'pointer',
  },
}));

export default useStyles;
