import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    padding: theme.mainPagePadding,
    overflow: 'hidden',
    '@media (max-width:991px)': {
      padding: '0 8px',
    },
    '@media (max-width:600px)': {
      padding: 0,
    },
  },
}));

export const PageContentUI = ({ children }) => {
  const styles = useStyles();

  return (
    <Box className={styles.content}>
      {children}
    </Box>
  );
};

export default PageContentUI;
