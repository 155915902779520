import React, { useState, createRef } from 'react';

import UnCheckIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckIcon from '@material-ui/icons/CheckBoxOutlined';
import Box from '@material-ui/core/Box';
import LoadMore from '../components/Pagination/LoadMore';
import { useStyles } from './styles/FlexGrid';

const FlexGridTable = (props) => {
  const { items } = props;
  const { ImageItem, ImageOverlay, ImageFooter, cardActionButton } = props;
  const { selected, setSelected, onDoubleClick, handleGoto } = props;
  const { isMore, fetchMoreFn } = props;

  const classes = useStyles(selected);
  const parentRef = createRef(null);
  const [posLeft, setPosLeft] = useState(0);
  const [timer, setTimer] = useState();

  const handleSelect = (id) => (evt) => {
    evt.stopPropagation();

    if (!timer) {
      if (setSelected) {
        if (selected.includes(id)) {
          setSelected(selected.filter((item) => item !== id));
        } else {
          setSelected([id]);
        }
      }
      setTimer(setTimeout(() => {
        setTimer();
      }, 250));
    } else {
      if (onDoubleClick) onDoubleClick(id);
      clearTimeout(timer);
      setTimer();
    }
  };

  const handleScroll = () => {
    if (-parentRef.current.scrollLeft !== posLeft) {
      setPosLeft(-parentRef.current.scrollLeft);
    }
  };

  return (
    <Box className={classes.grid}>
      <Box className={classes.gridItemsScroll} ref={parentRef} onScroll={handleScroll}>
        <Box className={classes.gridItems}>

          {items.map((item, index) => (
            <Box key={index.toString()} className={selected.includes(index) ? classes.itemSelected : classes.item} onClick={handleSelect(index)} aria-hidden="true">
              <Box className={classes.imageItem}>
                <ImageItem node={item.node} />
                <ImageOverlay node={item.node} index={index} cardActionButton={cardActionButton} handleGoto={handleGoto} />
              </Box>
              <ImageFooter node={item.node} />
              {selected.includes(index)
                ? <CheckIcon className={classes.cardSelectIcon} />
                : <UnCheckIcon className={classes.cardSelectIcon} />}
            </Box>
          ))}

          {isMore && <LoadMore container parent={parentRef} fetchMoreFn={fetchMoreFn} />}
        </Box>
      </Box>
    </Box>
  );
};

export default FlexGridTable;
