import React from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

import { Image } from '../../Image/Image';
import { CardImageLayer } from '../../../componentsUI/CardImageLayer';
import { CardImageWrapper } from '../../../componentsUI/CardImageWrapper';
import defaultPhoto from '../../../images/alma-default.jpg';

export const AdvancedToolCardImageItem = ({ node }) => {
  const { t } = useTranslation();
  return (
    <CardImageWrapper>
      <CardImageLayer>
        <Box className="name">{node.provider.name}</Box>
        <Box className="tags">
          {node.ceCertification && <Box className="category">CE</Box>}
          {node.fdaCertification && <Box className="category">FDA</Box>}
          {node.research && <Box className="category">{t('research')}</Box>}
          {node.category && <Box className="category">{node.category}</Box>}
          {node.speciality && <Box className="category">{t(node.speciality.toLowerCase())}</Box>}
          {node.bodyRegion && <Box className="category">{t(node.bodyRegion.toLowerCase())}</Box>}
        </Box>
      </CardImageLayer>
      <Image src={node.image || node.provider.logo || defaultPhoto} alt="tool" />
    </CardImageWrapper>
  );
};

export default AdvancedToolCardImageItem;
